import { template as template_cf3d32d3f0b544e3b322e31454fc9e04 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_cf3d32d3f0b544e3b322e31454fc9e04(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
