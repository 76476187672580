import { template as template_1a81effefa144c45b2b9b3ac3d586fa5 } from "@ember/template-compiler";
const WelcomeHeader = template_1a81effefa144c45b2b9b3ac3d586fa5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
