import { template as template_36e9023783f1487d9cb710c33c5f68b1 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import FKFieldset from "discourse/form-kit/components/fk/fieldset";
import FKControlRadioGroupRadio from "./radio-group/radio";
export default class FKControlRadioGroup extends Component {
    static controlType = "radio-group";
    static{
        template_36e9023783f1487d9cb710c33c5f68b1(`
    <FKFieldset
      class="form-kit__control-radio-group"
      @title={{@title}}
      @subtitle={{@subtitle}}
      ...attributes
    >
      {{yield
        (hash
          Radio=(component FKControlRadioGroupRadio value=@value field=@field)
        )
      }}
    </FKFieldset>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
